import React from 'react'
import Placeholder from '../components/placeholder'
import Breadcrumbs from '../components/breadcrumbs'
import ResponsabileReparto from '../components/responsabile-reparto'
import DoveSiamo from '../components/dove-siamo'
import Helmet from 'react-helmet'
import Layout from "../components/layout"
import DocumentiUtili from '../components/documenti-utili'
import { graphql } from "gatsby"

class Esoticienonconvenzioanli extends React.Component {

  render() { 
    const result = this.props.data.allContentfulSchedaServizio;
    const servizi = !!result?result.edges:[];
    const intro = this.props.data.contentfulContenutoGenerico;
    const responsabile =  this.props.data.responsabile;

    const breadcrumb = [{"title":"Reparti","link":"/reparti/"},{"title":"Esotici e non convenzioanali"}];

    return (
      <Layout  location={this.props.location}>
        <div className="container">
          <Helmet title="Esotici e non convenzionali"/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <section id="servizi-cani-gatti" className="section"  aria-labelledby="region1">
                <h1 id="region1" className="section__title">Esotici e non convenzionali</h1>
                {!!intro && 
                  <div dangerouslySetInnerHTML={{ __html: intro.abstract.childMarkdownRemark.html }} >
                  
                  </div>
                }

                <h2><strong>Servizi clinici</strong></h2>
        
                <div className="row  flex-wrap ">
                  {servizi.map((item,index) => {
                    
                      let dataserv = {
                        "title": item.node.titolo,
                        "link": "/esotici-e-non-convenzionali/"+item.node.friendlyUrl+"/"
                        };
                      
                        return ( 
                          <div className="col-6" key={index}>
                            <Placeholder data={dataserv}/>
                          </div>
                        ) 
                      
                  })}
                </div>
              </section>
             {/*  <section id="servizi-diagnositica" className="section"  aria-labelledby="region2">
                <h1 id="region2" className="section__title">Servizi di diagnostica</h1>
                <div className="row flex-wrap">
                {diagnostici.horizontal.map((item,index) => {
                    return <div key={index} className="col-6">
                      <Placeholder data={item}/>
                    </div>
                  })}
                </div>
              </section>   */}  
            </div>
            <div className="col-md-4">
              {!!responsabile && 
                <ResponsabileReparto data={responsabile}/>
              }
              {!!intro.titoloSpallaDestra &&
                <div className="webcontent">
                  <h2 className="webcontent__title">{intro.titoloSpallaDestra}</h2>
                    <div className="webcontent__abstract breakword" dangerouslySetInnerHTML={{ __html: intro.testoSpallaDestra.childMarkdownRemark.html }} >
                    
                    </div>
                </div>
              }
              {!!intro.attachment &&
                <DocumentiUtili data={intro.attachment}/>
              }
            
              <DoveSiamo/>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default Esoticienonconvenzioanli;


export const query = graphql`
query EsoticiQuery{
  allContentfulSchedaServizio(sort: {fields: [titolo]}, filter: {specie: {in: "Esotici e non convenzionali"}, node_locale: {eq: "it-IT"} }) {
    edges {
     node {
      id
      titolo
      friendlyUrl
      specie
    	}
    }
  }
  contentfulContenutoGenerico (contentful_id: {eq: "53rVXFkjHW4W0a0GweaAaQ"}) {
  	abstract {
      childMarkdownRemark {
        html
      }
    }
    titoloSpallaDestra
    testoSpallaDestra {
      childMarkdownRemark {
        html
      }
    }
    attachment {
      title
      file {
        url
      }
    }
  }
  responsabile: contentfulResponsabileReparto(reparti: {in: "Animali esotici e non convenzionali"}, node_locale: {eq: "it-IT"}) {
    nome
    cognome
    schedaStaff {
      friendlyUrl
    }
    foto {
      file {
        url
      }
    }
  }
  
}
`